// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/* eslint-disable import/prefer-default-export */

/**
 * Extracts the entity type and ID from the key.
 *
 * @param {object} item - The item to extract the entity type and ID from.
 * @param {string} item.key - The key of the entity.
 *
 * @returns {object} - The entity type and ID.
 */
export const getTypeAndId = item => {
  const [entityType, entityId] = item.key.split(':')

  return { entityType, entityId }
}
