// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {
  GET_USER_SESSIONS_LIST_SUCCESS,
  GET_ACTIVE_USER_SESSION_ID_SUCCESS,
} from '@console/store/actions/sessions'

const defaultState = {
  sessions: undefined,
  totalCount: undefined,
  activeSessionId: undefined,
}

const session = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_USER_SESSIONS_LIST_SUCCESS:
      return {
        ...state,
        sessions: payload.sessions,
        totalCount: payload.sessionsTotalCount,
      }
    case GET_ACTIVE_USER_SESSION_ID_SUCCESS:
      return {
        ...state,
        activeSessionId: payload,
      }
    default:
      return state
  }
}

export default session
