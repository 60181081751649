// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

import Message from '@ttn-lw/lib/components/message'

import DefaultRoutingPolicyForm from '@console/components/default-routing-policy-form'

import m from './messages'

import style from './admin-packet-broker.styl'

const DefaultRoutingPolicyView = () => (
  <div className="item-12">
    <Message
      content={m.routingPolicyInformation}
      component="p"
      className={style.routingPolicyInformation}
    />
    <DefaultRoutingPolicyForm />
  </div>
)

export default DefaultRoutingPolicyView
