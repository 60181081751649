// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useEffect, useState } from 'react'

import Icon, {
  IconMoodLookUp,
  IconMoodLookDown,
  IconMoodLookRight,
  IconMoodLookLeft,
  IconMoodCry,
} from '@ttn-lw/components/icon'

import PropTypes from '@ttn-lw/lib/prop-types'

const LookingLuke = ({ className }) => {
  const [icon, setIcon] = useState(IconMoodLookUp)

  useEffect(() => {
    const interval = setInterval(() => {
      const icons = [
        IconMoodLookUp,
        IconMoodLookDown,
        IconMoodLookRight,
        IconMoodLookLeft,
        IconMoodCry,
      ]
      const randomIndex = Math.floor(Math.random() * icons.length)
      setIcon(icons[randomIndex])
    }, 1500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return <Icon icon={icon} className={className} size={28} />
}

LookingLuke.propTypes = {
  className: PropTypes.string,
}

LookingLuke.defaultProps = {
  className: undefined,
}

export default LookingLuke
