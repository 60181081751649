// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// Top level entities.
export const APPLICATION = 'APPLICATION'
export const END_DEVICE = 'END_DEVICE'
export const GATEWAY = 'GATEWAY'
export const ORGANIZATION = 'ORGANIZATION'
export const USER = 'USER'
export const CLIENT = 'CLIENT'

export const ALL = 'ALL'

export const entitySdkServiceMap = Object.freeze({
  application: 'Applications',
  gateway: 'Gateways',
  organization: 'Organizations',
  users: 'Users',
  client: 'Clients',
})
